import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Img from 'gatsby-image';
import { Facebook, Instagram, Linkedin } from 'react-feather';
import Layout from '../../components/layout';
import useMediaQuery from '../../hooks/useMediaQuery';

import styles from './contact.module.css';

function Address(props) {
  const { align, title, address, tel, email, url } = props;
  const isMobile = useMediaQuery('(max-width:480px)');
  return (
    <div
      style={{ textAlign: isMobile ? 'center' : align }}
      className={`${styles.flex1} ${isMobile ? styles.mobileMargin : ''}`}
    >
      <div>{title}</div>
      <div>{address}</div>
      <div>{tel}</div>
      <div>
        <a className={styles.url} href={`mailto:${email}`}>
          {email}
        </a>
      </div>
      <div>
        <a className={styles.url} href={`https://${url}`}>
          {url}
        </a>
      </div>
    </div>
  );
}
Address.propTypes = {
  align: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  tel: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

function LegalNotice(props) {
  const { locale, align } = props;
  const isMobile = useMediaQuery('(max-width:480px)');

  const isFrench = locale === 'fr';

  function getVATAndCapital(VAT, capital) {
    if (VAT) {
      return isFrench ? `N° TVA : ${VAT} - CAPITAL SOCIAL : ${capital}` : `VAT N°: ${VAT} - SHARE CAPITAL: ${capital}`;
    }
    return isFrench ? `CAPITAL SOCIAL : ${capital}` : `SHARE CAPITAL: ${capital}`;
  }

  function getSirenOrIdentification(siren, identification) {
    if (siren) return isFrench ? `N° SIREN : ${siren}` : `SIREN N°: ${siren}`;
    return isFrench ? `N° D’IDENTIFICATION : ${identification}` : `IDENTIFICATION N°: ${identification}`;
  }

  function getHostAddress(host) {
    return isFrench ? `HÉBERGEUR : ${host}` : `HOST: ${host}`;
  }

  function getNcAddress(address) {
    return isFrench ? `ADRESSE : ${address}` : `ADDRESS: ${address}`;
  }

  const { ordre, VAT, capital, siren, identification, host, address } = props;

  return (
    <div
      style={{ textAlign: isMobile ? 'center' : align }}
      className={`${styles.flex1} ${isMobile ? styles.mobileMargin : ''}`}
    >
      <div>{ordre}</div>
      <div>{getVATAndCapital(VAT, capital)}</div>
      <div>{getSirenOrIdentification(siren, identification)}</div>
      <div>{(host && getHostAddress(host)) || getNcAddress(address)}</div>
    </div>
  );
}
LegalNotice.propTypes = {
  align: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  ordre: PropTypes.string.isRequired,
  VAT: PropTypes.string,
  capital: PropTypes.string.isRequired,
  siren: PropTypes.string,
  identification: PropTypes.string,
  host: PropTypes.string,
  address: PropTypes.string,
};
LegalNotice.defaultProps = {
  VAT: '',
  siren: '',
  identification: '',
  host: '',
  address: '',
};

function ContactTemplate(props) {
  const contact = get(props, 'data.contentfulContact');
  const location = get(props, 'location');
  return (
    <Layout location={location}>
      <Helmet title="Contact" />
      <div className={styles.addressWrapper}>
        <Address
          align="center"
          title={contact.titreAgenceFrance}
          address={contact.adresseAgenceFrance}
          tel={contact.telephoneAgenceFrance}
          email={contact.email}
          url={contact.siteWeb}
        />
      </div>
      {/* <div className={styles.socialNetworkContainer}>
        <div>
          <a href={contact.facebook} target="_blank" rel="noreferrer">
            <Facebook />
          </a>
        </div>
        <div>
          <a href={contact.instagram} target="_blank" rel="noreferrer">
            <Instagram />
          </a>
        </div>
        <div>
          <a href={contact.linkedin} target="_blank" rel="noreferrer">
            <Linkedin />
          </a>
        </div>
      </div> */}
      <div className={styles.legalNoticeWrapper}>
        <LegalNotice
          align="left"
          locale={contact.node_locale}
          ordre={contact.ordreArchiFrance}
          VAT={contact.tvaFrance}
          capital={contact.capitalSocialFrance}
          siren={contact.siren}
          host={contact.hebergeur}
        />
      </div>
    </Layout>
  );
}

export default ContactTemplate;

export const pageQuery = graphql`
  query ContactPage($locale: String!) {
    contentfulContact(node_locale: { eq: $locale }) {
      node_locale
      titreAgenceFrance
      titreAgenceNc
      adresseAgenceFrance
      adresseAgenceNc
      telephoneAgenceFrance
      telephoneAgenceNc
      email
      siteWeb
      ordreArchiFrance
      ordreArchiNc
      tvaFrance
      capitalSocialFrance
      capitalSocialNc
      siren
      identification
      hebergeur
      adresseNc
      linkedin
      facebook
      instagram
      imageLocalisation {
        id
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;
